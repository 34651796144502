import React from "react";

import "./styles.css";
import { Button, Image } from "react-bootstrap";
import { gandi, layer, network } from "../../assets";

const SecondaryMainCard = () => {
  return (
    <div className="secondary-main-card">
      <h1>
        Lightning fast and cheap to use, Gandi gives back to users with Proof of
        Liquidity rewards and creates new shared value on every chain through GG
      </h1>
      <div className="secondary-box">
        <div className="secondary-box-1">
          {/* <div className="secondary-box-1-gradient"></div> */}
          <Image src={layer} />
          <p>
            Existing as an abstracted meta-layer that connects every user across
            every blockchain …
          </p>
        </div>
        <div className="secondary-box-1">
          <Image src={gandi} />
          <p>
            With users being able to use GG across any chain to eliminate fees,
            or profit from gas fees by trading GG …
          </p>
        </div>
        <div className="secondary-box-1">
          <Image src={network} />
          <p>
            Forging a central nexus of new value creation for the multi-chain
            world with the power of GG
          </p>
        </div>
      </div>

      <Button variant="dark" style={{ borderRadius: 90, padding: 15 }}>
        Learn more
      </Button>
    </div>
  );
};

export default SecondaryMainCard;
