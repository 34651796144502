import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { logo } from "../../assets";

import "./styles.css";

const Footer = () => {
  return (
    <div className="footer">
      <Image src={logo} width={120} height={43.69} />

      <p className="footer-text">© 2024 Gandi. All rights reserved.</p>
    </div>
  );
};

export default Footer;
