import React from "react";
import {
  Container,
  Nav,
  Navbar as NavMain,
  Image,
  Button,
} from "react-bootstrap";
import { logo } from "../../assets";

import "./styles.css";

const Navbar = () => {
  return (
    <NavMain expand="md">
      <NavMain.Brand href="#home">
        <Image src={logo} width={120} height={43.69} />
      </NavMain.Brand>

      <NavMain.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link href="#home" color="rgba(18, 18, 18, 1)">
            Home
          </Nav.Link>
          <Nav.Link href="#gas-tracker" color="rgba(18, 18, 18, 1)">
            Gas Tracker
          </Nav.Link>
          <Nav.Link href="#earn" color="rgba(18, 18, 18, 1)">
            Earn
          </Nav.Link>
          <Nav.Link href="#leaderboard" color="rgba(18, 18, 18, 1)">
            Leaderboard
          </Nav.Link>
          <Nav.Link href="#about" color="rgba(18, 18, 18, 1)">
            About
          </Nav.Link>
        </Nav>
      </NavMain.Collapse>

      <div className="navbar-btn">
        <p className="navbar-btn-text">Connect Wallet</p>
      </div>

      <NavMain.Toggle aria-controls="basic-navbar-nav" />
    </NavMain>
  );
};

export default Navbar;
