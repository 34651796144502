import React from "react";

import "./styles.css";
import { Image } from "react-bootstrap";
import { blockchain, home, puzzle } from "../../assets";

const MainCard = () => {
  return (
    <div className="main-box">
      {/* <div className="main-box-gradient"></div> */}
      <h1>Not just another chaiN</h1>
      <p>
        Gandi is the meta-layer for value discovery, interconnecting every chain
        and dApp by rewarding users on Gandi with credits (GG) to transact with
        on every other blockchain
      </p>
      <div className="nested-box">
        <div className="nested-box-1">
          {/* <div className="nested-box-1-gradient"></div> */}
          <Image src={blockchain} />
          <p>
            Every blockchain is competing with other chains in an endless battle
            for dominance, users and dApps …
          </p>
        </div>
        <div className="nested-box-1">
          <Image src={puzzle} />
          <p>
            With millions users and dollars split across chains, where value is
            only shared via bridges & complex routes …
          </p>
        </div>
        <div className="nested-box-1">
          <Image src={home} />
          <p>
            Gandi ends the war of the chains - the home for shared value,
            working together instead of fighting for power 
          </p>
        </div>
      </div>
    </div>
  );
};

export default MainCard;
