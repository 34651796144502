import React from "react";

import "./styles.css";
import { Button, Image } from "react-bootstrap";
import { ContactText, telegram, twitter } from "../../assets";

const Contact = () => {
  return (
    <>
      <div className="contact-box">
        <div className="contact-box-text">
          <h1>NEVER PAY GAS AGAIN</h1>
        </div>
        {/* <Image src={ContactText} style={{ height: "40%" }} /> */}

        <div className="contact-btn-box">
          <Button variant="dark" className="contact-btn">
            <Image src={telegram} />
            <p>Telegram</p>
          </Button>

          <Button variant="dark" className="contact-btn">
            <Image src={twitter} />
            <p>X(Twitter)</p>
          </Button>
        </div>
      </div>
      <div className="contact-lower-gradient"></div>
    </>
  );
};

export default Contact;
