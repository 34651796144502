import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";

import HeroAnimation from "../../animations/hero-animation.json";

import "./styles.css";

const Hero = () => {
  // const [startAnimation, setStartAnimation] = useState(false);
  // useEffect(() => {
  //   const handleScroll = () => {
  //     // Do something when user scrolls
  //     console.log("User scrolled!");
  //     setStartAnimation(!startAnimation);
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []); // Empty dependency array ensures the effect runs only once

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: HeroAnimation,
  };
  return (
    <div className="hero-section">
      <h1>The Modular Blockchain That Pays For Your Gas On Every Chain</h1>
      <div className="animation-box">
        <Lottie options={defaultOptions} isClickToPauseDisabled />
      </div>
    </div>
  );
};

export default Hero;
