import React from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import MainCard from "../../components/MainCard";
import SecondaryMainCard from "../../components/SecondaryMainCard";
import Contact from "../../components/Contact";
import Hero from "../../components/Hero";

const Home = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <MainCard />
      <SecondaryMainCard />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
